import { useContext } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import AuthenticationContext from "../auth/AuthenticationContext";

export default function FeedbackToast() {
  const { success, setSuccess, message, mode, duration } = useContext(
    AuthenticationContext
  );

  return (
    <>
      <ToastContainer
        position='top-end'
        style={{ marginTop: "20px", marginRight: "20px", width: "fitContent" }}
      >
        <Toast
          bg={mode}
          onClose={() => setSuccess(false)}
          show={success}
          delay={duration}
          autohide
        >
          <Toast.Header
            style={{ display: "flex", justifyContent: "space-between" }}
            closeButton={false}
          >
            <strong>Action</strong>
            <small>now</small>
          </Toast.Header>
          <Toast.Body style={{ color: "white" }}>
            <i className='bi bi-egg-fried'></i> {message}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}
