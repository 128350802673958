import {Link} from 'react-router-dom';
import Login from './auth/Login';
import logoBeta from '../resources/logo_beta.png';
import RequestPasswordResetModel from './auth/RequestPasswordResetModel';
import {useState} from 'react';

import '../styles/LoginPage.scss';


export default function LoginPage() {
    const [showPasswordReset, setShowPasswordReset] = useState(false)

    const togglePopup = () => {
        setShowPasswordReset(!showPasswordReset)
    }

    return (
        <>
            <div id='login-view'>
                <div id="logo-container">
                    <img src={logoBeta} id="login-logo" alt="logo"/>
                </div>
                <div id="login-container">
                    <Login/>
                    <div id="alternatives-container">
                        <div>
                            <Link to="/register">Register</Link>
                        </div>
                        <div>
                            <u onClick={togglePopup} style={{color: "#176AD6", cursor: "pointer"}}>Forgot Password?</u>
                            {showPasswordReset && <RequestPasswordResetModel closePopup={togglePopup}/>}
                        </div>
                    </div>
                </div>
                <div className='footer'>
                    <a href='https://myunicbrain.com/impressum/'>Legal notice</a>
                    <a href='https://myunicbrain.com/nutzungsbedingungen/'>Terms of use</a>
                    <a href='https://myunicbrain.com/datenschutz/'>Privacy policy</a>
                </div>
            </div>
        </>
    )
}