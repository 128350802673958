import { Route, Routes } from "react-router-dom"
import Error404 from "./Error404"
import Messenger from "./messages/Messenger"
import Authorized from "./auth/Authorized"
import LoginPage from "./LoginPage"
import RegisterPage from "./RegisterPage"
import Layout from "./Layout"
import SettingsPage from "./SettingsPage"
import FilesPage from "./FilesPage"
import SharedFilesPage from "./SharedFilesPage"
import DashboardPage from "./DashboardPage"
import React from "react";


function AppRouter() {

    return (
        <>
        <Authorized authorized={
            <Routes>
                <Route path="/" element={<DashboardPage />} />

                <Route path="settings" element={<SettingsPage/>} />
                <Route path="files" element={<FilesPage />} />
                <Route path="found_files" element={<FilesPage />} />
                <Route path="messages" element={<Layout children={<Messenger /> }/>} />
                <Route path="domains" element={<Layout />} />
                <Route path="shared-files" element={<SharedFilesPage />} />

                <Route path="*" element={<Error404 />} />
            </Routes>}
        notAuthorized={
            <Routes>
                <Route path="*" element={<LoginPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="register" element={<RegisterPage />} />
            </Routes>
        } />
        </>
    )
}

export default AppRouter