import { userCredentials } from "../../models/auth.models";
import { useState } from "react";
import AuthForm from "./AuthForm";
import DisplayErrors from "../elements/DisplayErrors";
import { useNavigate } from "react-router-dom";
import api, { endpoints } from "../../utils/api";
import useFeedback from "../elements/customHooks/useFeedback";


export default function Register() {
    const [errors, setErrors] = useState<string[]>([])
    const navigate = useNavigate();
    const { done } = useFeedback();

    async function register(credentials: userCredentials) {
        try {
            setErrors([]);
            const response = await api.post(endpoints.accounts + '/register', credentials)

            if (response.status === 200) {
                done("Registration completed", "success", 3000);
                navigate('/login');
            }
        } catch (error) {
            setErrors([error.message]);
        }
    }

    return (
        <>
            <div id="register">
                <h3>Register</h3>
                <DisplayErrors errors={errors} />
                <AuthForm
                    model={{ email: '', password: '' }}
                    isRegister={true}
                    onSubmit={async values => await register(values)}
                />
            </div>
        </>
    )
}