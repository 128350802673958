export interface fileDTO {
    id: string;
    owner: string;
    name: string;
    createdDate: string;
    sharedWith: string[];
    size: number;
    domain: string;
    indexStatus: string;
    contentTypes: string;
}

export interface fileURLModel {
    fileURL: string, 
    type: string, 
    blob: Blob | null
}

export interface sharedFileDTO {
    fileId: string;
    owner: string;
    name: string;
    status: string;
    createdDate: string;
    size: number;
}

export interface fileCreationDTO {
    name: string;
    blob?: File;
    blobURL?: string;
}

export interface fileShareDTO {
    fileId: string;
    email: string;
}

export interface fileRenameDTO {
    fileId: string;
    newName: string;
}

export function adjustByteSize(size: number) {
    if (size / 1024 < 1000) {
        return (size / 1024).toFixed() + ' KB';
    } else if (size / 1024 ** 2 < 1000) {
        return (size / 1024 ** 2).toFixed(2) + ' MB';
    } else if (size / 1024 ** 3 < 1000) {
        return (size / 1024 ** 3).toFixed(2) + ' GB';
    }
}
