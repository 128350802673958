import axios from 'axios';

// Define the base URL from the environment variable
const apiBaseURL = process.env.REACT_APP_API_URL;

// Custom Axios Instance
const api = axios.create({
    baseURL: apiBaseURL,
    withCredentials: true,
});

// Add a Response Interceptor
api.interceptors.response.use(
    response => {
        // If the response is successful, simply return it
        return response;
    },
    error => {
        let errorMessage = "An unknown error occurred.";

        // Check if the error response contains the structured error message
        if (error.response && error.response.data && error.response.data.Message) {
            errorMessage = error.response.data.Message;
            console.error(`Statuscode: ${error.response.status} - Message: ${errorMessage}`);
        }

        // Throw the extracted error message
        throw new Error(errorMessage);
    }
);

// Define Endpoints
export const endpoints = {
    accounts: `${apiBaseURL}/accounts`,
    files: `${apiBaseURL}/files`,
    tasks: `${apiBaseURL}/tasks`,
    chats: `${apiBaseURL}/chat`,
    chatHub: `${apiBaseURL}/chat-hub`,
    domains: `${apiBaseURL}/domain`
}

// Export the customized api instance
export default api;
