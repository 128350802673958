import React, { ReactNode, useContext, useEffect, useState } from 'react';
import '../styles/Layout.scss';
import AuthenticationContext from './auth/AuthenticationContext';
import api, { endpoints } from '../utils/api';
import SideBar from './SideBar';
import {
    TableCellsIcon,
    ChatBubbleLeftIcon,
    DocumentDuplicateIcon,
    FolderOpenIcon,
} from '@heroicons/react/24/outline';
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from '../models/auth.models';
import HeaderContainer from './HeaderContainer';
import WebSocketContext, { WebSocketContextType } from './contexts/WebSocketContext';

const items = [
    { name: 'Dashboard', to: '/', icon: <TableCellsIcon /> },
    {
        name: 'Messages',
        to: '/messages',
        icon: <ChatBubbleLeftIcon />,
    },
    { name: 'Files', to: '/files', icon: <DocumentDuplicateIcon /> },
    {
        name: 'Shared Files',
        to: '/shared-files',
        icon: <FolderOpenIcon />,
    },
];

export interface MenuItem {
    name: string;
    to: string;
    icon: JSX.Element;
}

interface NewLayoutProps {
    children?: ReactNode;
    updatedUserData?: User | undefined | null;
}

const Layout: React.FC<NewLayoutProps> = ({ children, updatedUserData }) => {
    const [activeItem, setActiveItem] = useState<MenuItem>({
        name: '',
        to: '',
        icon: <></>,
    });

    const { user, setUser } = useContext(AuthenticationContext);
    const { newsCount } = useContext(WebSocketContext) as WebSocketContextType;

    useEffect(() => {
        updatedUserData && setUser(updatedUserData);
    }, [updatedUserData]);

    useEffect(() => {
        setUser(user);
    }, [user]);

    const location = useLocation();
    const navigate = useNavigate();
    const { setIsLoggedIn } = useContext(AuthenticationContext);

    useEffect(() => {
        const foundedItem = items.find((i) => location?.pathname === `${i.to}`);
        foundedItem
            ? setActiveItem(foundedItem)
            : setActiveItem({
                name: '',
                to: '',
                icon: <></>,
            });
    }, [location]);

    async function handleLogout() {
        try {
            await api.post(endpoints.accounts + '/logout');
            setIsLoggedIn(false);
            navigate('/');
        } catch (error) {
            console.error('Logout failed', error);
        }
    }

    return (
        <div className="layout">
            <SideBar items={items} activeItem={activeItem} handleLogout={handleLogout} newsCount={newsCount ?? 0} />
            <div className='main-wrapper'>
                <div className="content-wrapper">
                    <HeaderContainer
                        user={user}
                        items={items}
                        activeItem={activeItem}
                        handleLogout={handleLogout}
                    />
                    <div className="children-container">{children}</div>
                </div>
                <div className='footer'>
                    <a href='https://myunicbrain.com/impressum/'>Legal notice</a>
                    <a href='https://myunicbrain.com/nutzungsbedingungen/'>Terms of use</a>
                    <a href='https://myunicbrain.com/datenschutz/'>Privacy policy</a>
                </div>
            </div>
        </div>
    );
};

export default Layout;
